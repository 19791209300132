<template>
  
  <div class="container">

    <h5>My Dashboard</h5>
    
    <div class="dashboard-wrapper">
      
      <div class="dash-row">
        <div class="dash-item left" @click="$router.push('my-trucks')">
          <div class="dash-item-icon"><img src="@/assets/img/dash-truck.png" /></div>
          <div class="dash-item-title">My Trucks</div>
          <div class="dash-info"><span>Manage any trucks that you own</span></div>
        </div>
        <div class="dash-item right" @click="$router.push('my-details')">
          <div class="dash-item-icon"><img src="@/assets/img/dash-profile.png" /></div>
          <div class="dash-item-title">My Profile</div>
          <div class="dash-info"><span>Your personal settings and profile</span></div>
        </div>
      </div>
      <div class="dash-row last">
        <div class="dash-item left">
          <div class="dash-item-icon"><img src="@/assets/img/dash-logs.png" /></div>
          <div class="dash-item-title">Logs</div>
          <!-- <div class="dash-info"><span>All daily truck logs you need to do</span></div> -->
          <div class="dash-info"><span>Logs page coming soon!</span></div>
        </div>
        <div class="dash-item right" @click="$router.push('my-fleets')">
          <div class="dash-item-icon"><img src="@/assets/img/dash-fleet.png" /></div>
          <div class="dash-item-title">Fleet</div>
          <div class="dash-info"><span>Manage your Fleet of trucks</span></div>
        </div>
      </div>

      <div class="clear"></div>

      <div class="spacer s60"></div>

      <div class="has-text-centered">
        <button class="button is-small" @click="windowReload('newload')">
          Refresh app
        </button>

        <div class="spacer s50"></div>
      </div>

    </div>

  </div>

</template>


<script>
  
import firebase from "firebase/app";
import "firebase/auth";
import NProgress from "nprogress";
import gmapsInit from "../utils/gmaps";

export default {
  name: 'MyDashboard',
  components: {

  },
  async mounted() {
    NProgress.start();
    var self = this;

    // LOAD ALL FROM DASHBOARD AND GET NOTIFIED WHEN LOADED
    // this.$store.dispatch("LOADDASHBOARD");

    Promise.all([

      // this.$store.dispatch("SETUSERPROFILE"),
      // this.$store.dispatch("updateUserState"),
      // this.$store.dispatch("SETMYACTIVEVESSELS"),
      // this.$store.dispatch("SETMYVESSELS"),
      // this.$store.dispatch("SETMYCREWEDVESSELDATA"),
      // this.$store.dispatch("setUser"),
      // this.$store.dispatch("LOADVESSELSTARTUPITEMS"),
      // this.$store.dispatch("LOADALLVESSELCREW"),
      // this.$store.dispatch("LOADDIVEPLAN"),
      this.$store.dispatch("UPDATEMYFLEETS"),
      // this.$store.dispatch("UPDATEALLCREWEDVESSELS"),
      this.$store.dispatch("SETSUBSCRIPTIONINFO"),

    ]).finally(() => {
      // using "finally" so even if there are errors, it stops "loading"
      this.loadingStore = false;
      NProgress.done();
      // console.log('Role is:', this.userRole);
      // if (self.userRole == 'fleet') {
      //   self.$router.push('/fleet-dashboard').catch(err => {})
      // }
    });

    firebase.auth().onAuthStateChanged(this.onUserLogin);
    try {
      const google = await gmapsInit();
      const geocoder = new google.maps.Geocoder();
      //const map = new google.maps.Map(this.$el);

      geocoder.geocode({ address: "Australia" }, (results, status) => {
        if (status !== "OK" || !results[0]) {
          throw new Error(status);
        }
        // map.setCenter(results[0].geometry.location);
        // map.fitBounds(results[0].geometry.viewport);
      });
      //
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            var pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            //console.log(pos);

            // var latlng = {
            //  pos.lat, pos.lng
            // };

            // start reverse geocoding
            var geocoder = new google.maps.Geocoder();
            self.geocodeLatLng(geocoder, pos);
          },
          function() {
            //handleLocationError(true, infoWindow, map.getCenter());
            self.googleLocation = "Please refresh this page to load your location.";
          }
        );
      } else {
        //Browser doesn't support Geolocation
        //handleLocationError(false, infoWindow, map.getCenter());
        // var errorMsg = browserHasGeolocation ? "Error: The Geolocation service failed." : "Error: Your browser doesn't support geolocation.";
        var errorMsg = "Error: The Geolocation service failed, sorry.";
        self.googleLocation = errorMsg;
      }
      //
    } catch (error) {
      console.error(error);
    }
    //console.log('Tour status: ',this.userData.takenDashboardTour);
    // if (!this.takenDashboardTour) {
    //   this.$tours["dashboardTour"].start();
    // }
    if (window.matchMedia("(display-mode: standalone)").matches) {
      console.log("display-mode is standalone");
      this.isStandalone = true;
    }
    if (window.navigator.standalone === true) {
      console.log("display-mode is standalone");
      this.isStandalone = true;
    }
    setTimeout(function() {
      NProgress.done();
      if (self.isIOS && !self.isStandalone && !self.seenPrompt) {
        self.showAppPrompt = true;
      } else {
        self.showAppPrompt = false;
      }
    }, 1500);
  },

  data: function() {
    return {
      loadingStore: true,
      firebaseid: "",
      firebaseemail: "",
      firebasedisplayname: "",
    }
  },

  computed: {
    userRole() {
      return this.$store.getters.getSubscriptionInfo.userrole;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
  },

  methods: {
    
    onUserLogin: function(user) {
      if (user) {
        this.firebaseemail = user.email;
        this.firebaseid = user.uid;
        this.firebasedisplayname = this.userData.username;
        this.$store.dispatch("updateUserState");
        // if (this.userData.takenDashboardTour != undefined) {
        //   this.takenDashboardTour = this.userData.takenDashboardTour;
        // } else {
        //   this.takenDashboardTour = true;
        // }
        // if (this.userData.seenAppPrompt != undefined) {
        //   this.seenPrompt = this.userData.seenAppPrompt;
        // } else {
        //   this.seenPrompt = true;
        // }

        this.loadTrucks();
        // this.loadAlerts();
      }
    },

     windowReload: function(method) {
      let self = this;
      let message =
        method == "reload"
          ? self.firebasedisplayname + " has force refreshed the app from the Dashboard (content loading...)"
          : self.firebasedisplayname + " has manually refreshed the app from the Dashboard";
      var userHasEngaged = firebase.functions().httpsCallable("userHasEngaged");
      userHasEngaged({
        userID: self.firebaseid ? self.firebaseid : "no-id",
        userName: self.firebasedisplayname
          ? self.firebasedisplayname
          : "A visitor",
        message: message,
        subject: "A user has refreshed the app",
      });
      window.location.reload();
    },

    geocodeLatLng: function(geocoder, pos) {
      var self = this;
      var latlng = {
        lat: parseFloat(pos.lat),
        lng: parseFloat(pos.lng),
      };
      geocoder.geocode({ location: latlng }, function(results, status) {
        if (status === "OK") {
          if (results[1]) {
            //map.setZoom(11);
            // var marker = new google.maps.Marker({
            //   position: latlng,
            //   map: map
            // });
            //infowindow.setContent(results[0].formatted_address);
            //infowindow.open(map, marker);
            //console.log(results[0].formatted_address);
            self.googleLocation = results[1].formatted_address;
            self.$store.dispatch("UPDATELOCATION", self.googleLocation);
          } else {
            //window.alert('No results found');
            self.googleLocation = "We could not find your location.";
          }
        } else {
          //window.alert('Geocoder failed due to: ' + status);
          self.googleLocation = "Geocoder failed due to: " + status;
        }
      });
    },

    loadTrucks() {
      // let self = this;

    },




  }




}




</script>




<style lang="scss" scoped>

  h5 {
    margin-top: 3rem;
    text-align: center;
  }
  
  .dashboard-wrapper {
    margin: 3rem auto;
    .dash-row {
      clear: left;
      .dash-item {
        width: 50%;
        padding: 2rem 2rem 1rem;
        cursor: pointer;
        text-align: center;
        border-bottom: 1px solid #f2f2f2;
        &.left {
          float: left;
          border-right: 1px solid #f2f2f2;
        }
        &.right {
          float: right;
        }
        .dash-item-icon {
          transition: 0.3s;
          max-width: 130px;
          margin: 0 auto;
          img {
            width: 100%;
          }
        }
        .dash-item-title {
          font-family: 'Jost', sans-serif;
          font-size: 1.5rem;
        }
        .dash-info {
          height: 3rem;
          span {
            opacity: 0;
            transition: 0.3s;
            color: orangered;
          }
        }
        &:hover {
          .dash-item-icon {
            transform: scale(1.1); 
          }
          .dash-info {
            span {
              opacity: 1;
            }
          }
        }
      }
      &.last {
        .dash-item {
          border-bottom: 0;
        }
      }
    }
  }


  @media only screen and (max-width: 600px) {

    .dashboard-wrapper {
      .dash-row {
        .dash-item {
          .dash-info {
            display: none;
          }
        }
      }
    }

  } 



</style>