<template>

  <div class="site-wrapper">
  
      <div class="container">

        <ul class="scroller">
          <li>
            <router-link to="my-dashboard">Dashboard</router-link>
          </li>
          <li class="is-active">
            <a aria-current="page">My Trucks</a>
          </li>
        </ul>

        <div v-if="isAndroid || isIOS" class="app-prompt">
          <h5>Get more from OffshoreSMS with our new App!</h5>
          <div class="download">
            <a v-if="isIOS" href="https://apps.apple.com/us/app/offshoresms/id1526656158" target="_blank"><img class="partners" src="@/assets/img/app-store.jpg" /></a>
            <a v-if="isAndroid" href="https://play.google.com/store/apps/details?id=offshoresms.appdev.nybble.com.smsapp" target="_blank"><img class="partners" src="@/assets/img/play-store.jpg" /></a>
          </div>
        </div>

        <div class="spacer s20"></div>
        
        <div class="card is-centered-mobile">
          <div>
            <div class="before-icon"><font-awesome-icon icon="truck" /></div>
            <h4 class="icon">My Trucks</h4>
            <div class="clear"></div>

            <div class="fleet-link" v-if="myTrucks.length">
              
              <!-- <div v-for="truck in myTrucks" :key="truck.truckID">
                <div class="notification">
                  <div class="item-title"><span class="item-name">{{ truck.truckName }}</span></div>
                  <div class="buttons no-margin">
                    <span @click="dailylogs('admin',truck.isActive,truck.truckID,truck.truckName,truck.truckUVI,truck.lastStartupID,truck.doesDiving)" class="button is-success">Truck Logs</span>
                    <span @click="toTruckEditSms(truck.truckID,truck.truckName,truck.truckUVI)" class="button is-link">View Truck</span>
                  </div>
                </div>
              </div> -->

              <div v-for="truck in myTrucks" :key="truck.truckID" class="content-wrapper" @click="toTruckEditSms(truck.truckID,truck.truckName,truck.truckUVI,truck.isPaidFor,false)">
                <div class="round-button-letters">
                  <span>{{makeLetters(truck.truckName)}}</span>
                </div>
                <div class="content-box">
                  <div class="content-align">
                    <h2>{{truck.truckName}}</h2>
                    <span>{{truck.truckUVI}}</span>
                  </div>
                </div>
                <div class="clear">
                  
                </div>
              </div>


            </div>

            <div class="" v-else>
              <p>You haven't added any of your own trucks yet.</p>
            </div>

            <div class="spacer s20"> </div>

            <div class="buttons">
              <span v-on:click="showAddTruck = true" class="button is-info">Add a truck</span>
              <span class="button is-danger" @click="showDeleteTruck = true">Delete truck</span>
            </div>

            <!-- <div class="buttons" v-if="myFleets.length > 0">
              <span class="button is-light" @click="showMoveTruck = true">Move a truck to a Fleet</span>
            </div> -->

            <div class="spacer s30"> </div>

            <!-- <div class="tag-wrapper has-text-centered">
              <div class="tags has-addons has-text-centered" @click="toUpgrade('add-trucks')">
                <span class="tag is-danger is-medium"><font-awesome-icon icon="star"/></span>
                <span class="tag is-success is-medium">Buy More Trucks</span>
              </div>
            </div> -->

          </div>
        </div>

        <hr>


        <div class="card is-centered-mobile">
          <div>
            <div class="before-icon"><font-awesome-icon icon="truck" /></div>
            <h4 class="icon">Trucks I Drive for Other People</h4>
            <div class="clear"></div>
            <div class="fleet-link" v-if="otherTrucks.length">

              <div v-for="truck in otherTrucks" :key="truck.truckID" class="content-wrapper" @click="toTruckEditSms(truck.truckID,truck.truckName,truck.truckUVI,truck.isPaidFor,true)">
                <div class="round-button-letters">
                  <span>{{makeLetters(truck.truckName)}}</span>
                </div>
                <div class="content-box">
                  <div class="content-align">
                    <h2>{{truck.truckName}}</h2>
                    <span>{{truck.truckUVI}}</span>
                  </div>
                </div>
                <div class="clear">
                  
                </div>
              </div>


            </div>
            <div class="" v-else>
              <p>You haven't been allocated any other trucks yet.</p>
            </div>

            <div class="spacer s80"> </div>

          </div>
        </div>
        

      </div>

      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showAddTruck }">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
          <header class="modal-card-head is-info">
            <p class="modal-card-title">New truck details:</p>
            <button class="delete" aria-label="close" @click.prevent="close"></button>
          </header>
          <section class="modal-card-body">
            <div class="form-group">
              <input class="input capitalise" type="text" v-model="newtruck.truckname" required />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Truck name:</label>
            </div>
            <div class="form-group">
              <input class="input capitalise" type="text" v-model="newtruck.truckuvi" required />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Truck Rego:</label>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-info" v-show="showAddTruckButton" @click="saveTruck">
              Save new truck
            </button>
            <button class="button cancel" @click.prevent="close">Cancel</button>
          </footer>
        </div>
      </div>

      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showDeleteTruck}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head is-danger">
              <p class="modal-card-title">Delete a truck</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">   
              
              <div class="select is-primary">
                <select v-model="deletedTruck">
                  <option disabled value="">Select a truck</option>
                  <option v-for="truck in myTrucks" :value="truck" :key="truck.truckID">
                    {{ truck.truckName }}
                  </option>
                </select>
              </div>
              <div class="spacer s20"> </div>
              <div class="delete-truck" v-if="deletedTruck != ''">
                <p>Are you sure you want to delete {{deletedTruck.truckName}}? This cannot be undone.</p>
              </div>
              <!-- <p v-if="deletedTruck != ''">This may take 24 hours to be deleted.</p> -->

            </section>
            <footer class="modal-card-foot">
              <button class="button is-danger" :disabled="deletedTruck == ''" @click.prevent="deleteTruck()">Yes, delete</button>
              <button class="button cancel" @click.prevent="close">Cancel</button>
            </footer>
        </div>
      </div>

      <!-- <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showMoveTruck}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head is-warning">
              <p class="modal-card-title">Move a truck to a fleet</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">   
              
              <div class="select is-primary">
                <select v-model="movedTruck">
                  <option disabled value="">Select a truck</option>
                  <option v-for="truck in myTrucks" :value="truck" :key="truck.truckID">
                    {{ truck.truckName }}
                  </option>
                </select>
              </div>
              <div class="spacer s20"> </div>
              <div class="delete-truck" v-if="movedTruck != ''">
                <p>Are you sure you want to move {{movedTruck.truckName}}?</p>
              </div>

            </section>
            <footer class="modal-card-foot">
              <button class="button is-warning" :disabled="movedTruck == ''" @click.prevent="moveTruck()">Yes, move</button>
              <button class="button cancel" @click.prevent="close">Cancel</button>
            </footer>
        </div>
      </div> -->



  </div>

</template>


<script>

import { db } from "../main.js";
import firebase from "firebase/app";
import "firebase/auth";
import NProgress from "nprogress";

export default {
  name: 'MyTrucks',
  components: {

  },
  async mounted() {
    firebase.auth().onAuthStateChanged(this.onUserLogin);
    this.getOS();
  },

  data: function() {
    return {
      loadingStore: true,
      firebaseid: "",
      firebaseemail: "",
      firebasedisplayname: "",
      isIOS: false,
      isAndroid: false,
      myTrucks: [],
      otherTrucks: [],
      showAddTruck: false,
      showDeleteTruck: false,
      newtruck: {
        truckname: "",
        truckuvi: "",
        truckID: "",
      },
      deletedTruck: '',
      showMoveTruck: false,
      movedTruck: '',
      trucksThatIDrive: [],
    }
  },

  computed: {
    userRole() {
      return this.$store.getters.getSubscriptionInfo.userrole;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },    
    showAddTruckButton() {
      return this.newtruck.truckname && this.newtruck.truckuvi;
    },
    myFleets() {
      return this.$store.getters.getMyFleets;
    },
  },

  methods: {
    
    getOS() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // console.log(userAgent);

      // Windows Phone must come first because its UA also contains "Android"
      // if (/windows phone/i.test(userAgent)) {
          // return "Windows Phone";
      // }

      if (/android/i.test(userAgent)) {
          // return "Android";
          this.isAndroid = true;
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          // return "iOS";
          this.isIOS = true;
      }

      // return "unknown";

    },

    makeLetters(fleetName) {
      let res = fleetName.split(" ");
      if (res.length > 1) {
        return res[0].substring(0, 1) + res[1].substring(0, 1);
      } else {
        return res[0].substring(0, 1);
      }
    },

    onUserLogin: function(user) {
      if (user) {
        this.firebaseemail = user.email;
        this.firebasedisplayname = this.userData.username;
        this.firebaseid = user.uid;
        this.loadMyTrucks();
        
        // this.loadCrewedTrucks();
        // this.loadMySavedData();
      }
    },

    close() {
      this.showAddTruck = false;
      this.showDeleteTruck = false;
      this.newtruck = {};
      this.deletedTruck = '';
      this.movedTruck = '';
      this.showMoveTruck = false;
    },

    async loadMyTrucks() {
      let self = this;
      self.myTrucks = [];
      self.myTrucksTemp = [];
      self.trucksThatIDrive = [];

      await db.collection("userdata").doc(this.firebaseid).onSnapshot(async function(doc) {

        self.myTrucksTemp = [];
        self.myTrucks = [];
        self.myTrucksTemp = doc.data().myTrucks ? doc.data().myTrucks : [];
        self.trucksThatIDrive = doc.data().trucksThatIDrive ? doc.data().trucksThatIDrive : [];
        self.otherTrucks = [];

        await self.loadOtherTrucks();

        await Promise.all(self.myTrucksTemp.map(async (truck) => {
          await db.collection('trucks').doc(truck.truckID).get().then(function(docRef) {
            self.myTrucks.push({
              truckID: truck.truckID,
              truckName: truck.truckName,
              truckUVI: truck.truckUVI,
              isPaidFor: docRef.data().isPaidFor ? docRef.data().isPaidFor : false,
            })
          });        
        }));

      });

    },

    async loadOtherTrucks() {
      let self = this;
      await Promise.all(self.trucksThatIDrive.map(async (truckID) => {
        await db.collection('trucks').doc(truckID).get().then(function(docRef) {
          self.otherTrucks.push({
            truckID: truckID,
            truckName: docRef.data().truckName,
            truckUVI: docRef.data().truckUVI,
            isPaidFor: docRef.data().isPaidFor ? docRef.data().isPaidFor : false,
          })
        });        
      }));

    },

    saveTruck: function() {
      // NProgress.start();
      let self = this;
      let newTruckName = self.newtruck.truckname.toUpperCase();
      let newTruckUVI = self.newtruck.truckuvi.toUpperCase();
      let newTruckArray = {
        truckName: newTruckName,
        truckUVI: newTruckUVI,
        truckOwnerID: self.firebaseid,
        truckOwnerName: self.firebasedisplayname,
        truckOwnerEmail: self.firebaseemail,
        isFleetTruck: false,
        isPaidFor: false,
        truckState: self.userData.userstate ? self.userData.userstate : '',
        serviceAunit: 'KM',
        serviceBunit: 'KM',
        serviceCunit: 'KM',
      }
      console.log(newTruckArray);
      let newTruckID = '';

      db.collection("trucks").add(newTruckArray)
      .then((docRef) => {
        newTruckID = docRef.id;
        db.collection("userdata").doc(self.firebaseid).update({
          myTrucks: firebase.firestore.FieldValue.arrayUnion({
            truckName: newTruckName,
            truckID: docRef.id,
            truckUVI: newTruckUVI,
          })
        })
        .then(() => {
          var loadPrestartChecklist = firebase.functions().httpsCallable('loadPrestartChecklist');
          loadPrestartChecklist({
            truckID: newTruckID,
          });
        })
        .then(() => {
          self.showAddTruck = false;
          self.newtruck = {};
          NProgress.done();
        });
      })
      .catch(error => {
          console.error("Error adding truck: ", error);
          self.showAddTruck = false;
          self.newtruck = {};
          NProgress.done();
      });

    },

    deleteTruck() {
      // NProgress.start();
      let self = this;
      // let listOfDriverIDs;
      // console.log('Delete:',this.deletedTruck);
      // db.collection('trucks').doc(this.deletedTruck.truckID).get().then(function(doc) {
      //   listOfDriverIDs = doc.data().listOfDriverIDs;
      // })
      db.collection("trucks").doc(self.deletedTruck.truckID).update({
        truckStatus: 'deleted'
      })
      .then(() => {
        // console.log(listOfDriverIDs);
        db.collection('userdata').doc(self.firebaseid).update({
          myTrucks: firebase.firestore.FieldValue.arrayRemove({
            truckID: self.deletedTruck.truckID,
            truckName: self.deletedTruck.truckName,
            truckUVI: self.deletedTruck.truckUVI
          })
        });
        var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
        addTruckRevision({
          userName: self.userData.username,
          userID: self.firebaseid,
          truckName: self.deletedTruck.truckName,
          truckID: self.deletedTruck.truckID,
          revisionDetails: 'Truck deleted',
        });
      })
      .then(() => {
        self.close();
        self.deletedTruck = '';
        NProgress.done();
      });

    },  

    moveTruck() {
      console.log(this.movedTruck);
    },

    toTruckEditSms(truckID,truckName,truckUVI,isPaidFor,isFleetTruck) {
      // console.log(truckID,truckName,truckUVI,isPaidFor);
      this.selectedTruckID = truckID;
      this.$store.dispatch('UPDATESELECTEDTRUCK',{truckName,truckID,truckUVI,isPaidFor});
      this.$router.push({ name: 'TruckEditSms', params: { 
        selectedTruckID : truckID,
        selectedTruckName : truckName,
        selectedTruckUVI : truckUVI,
        isFleetTruck: isFleetTruck
      } })
    }



  }




}




</script>




<style lang="scss" scoped>

  h5 {
    font-size: 1.4rem;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  .download {
    text-align: center;
  }


  .fleet-link {
    width: 100%;
    position: relative;
    cursor: pointer;
    .content-wrapper {
      .round-button-letters {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        text-align: center;
        position: relative;
        background: steelblue;
        display: block;
        margin: 0 2rem 2rem 0;
        float: left;
        transition: 0.3s;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        span {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          line-height: 1rem;
          font-size: 2.5rem;
          color: #fff;
          font-weight: 900;
        }
      }
      .content-box {
        height: 100px;
        width: calc(100% - 150px);
        float: left;
        position: relative;
        text-align: left;
        .content-align {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          position: relative;
          &:after {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            width: 0;
            bottom: -10px;
            background: steelblue;
            height: 5px;
            transition-property: width;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
          }
          h2 {
            font-size: 1.5rem;
            line-height: 1.8rem;
          }
          span {

          }
        }
      }
      &:hover {
        .content-align:after {
          left: 0;
          right: auto;
          width: 200px;
        }
      }
    }
    &:nth-of-type(2n) {
      .round-button-letters {
        background: cadetblue;
      }
      .content-box {
        .content-align {
          &:after {
            background: cadetblue;
          }
        }
      }
    }
    &:nth-of-type(3n) {
      .round-button-letters {
        background: skyblue;
      }
      .content-box {
        .content-align {
          &:after {
            background: skyblue;
          }
        }
      }
    }
    .content {
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
      left: 50%;
      display: block;
      margin: 0 auto;
      font-size: 1.5rem;
      line-height: 1.8rem;
      svg {
        display: block;
        margin: 1rem auto;
      }
      span {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 767px) { 

    .fleet-link {
      .content-wrapper {
        .round-button-letters {
          width: 100px;
          height: 100px;
          span {
            font-size: 2.3rem;
          }
        }
        .content-box {
          height: 100px;
          width: calc(100% - 150px);
          .content-align {
            h2 {
              font-size: 1.2rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }

  }


  @media only screen and (max-width: 479px) { 

    .fleet-link {
      .content-wrapper {
        .round-button-letters {
          width: 70px;
          height: 70px;
          margin-right: 1rem;
          span {
            font-size: 1.7rem;
          }
        }
        .content-box {
          height: auto;
          margin-bottom: 2rem;
          margin-top: 10px;
          .content-align {
            top: 0;
            transform: none;
            h2 {
              font-size: 1.2rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }

  }


</style>